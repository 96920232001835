<template>
  <div id="restrictDeposit">
    <div class="content_box">
      <div class="inner_max">
        <h3>{{ $t('restrictDeposit.header') }}</h3>
        <Result :info="true"> <div v-html="$t('restrictDeposit.info')"></div> </Result>
      </div>
    </div>
  </div>
</template>

<script>
import Result from '@/components/Result';

export default {
  name: 'RestrictDeposit',
  components: { Result }
};
</script>
